/**
 * Да отсохнут руки у того, кто добавит в этот модуль зависимость от jQuery.
 */
import AjaxNavigationLog from 'OK/AjaxNavigationLog';
import screens from 'OK/utils/screens';

/**
 * Конвертирует параметры из объекта в строку для передачи в POST-запросе
 * @param {Object} dataObject
 * @returns {string}
 */
function stringifyParams(dataObject) {
    var result = [],
        putItem = function(key, value) {
            result.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
        };

    for (var key in dataObject) {
        if (dataObject.hasOwnProperty(key)) {
            var item = dataObject[key];
            if (Array.isArray(item)) {
                item.forEach(putItem.bind(null, key));
            } else {
                putItem(key, dataObject[key]);
            }
        }
    }

    return result.join('&');
}

/**
 * @param {String} url
 */
function parseLink(url) {
    var indexOf = url && url.indexOf('?');
    if (indexOf === -1) {
        return {
            path: url,
            query: {}
        }
    }
    return {
        path: url.substring(0, indexOf),
        query: extractParams(url)
    }
}

function extractParams(url) {
    var params = {},
        indexOf = url && url.indexOf('?');

    if (indexOf > -1) {
        var query = url.substr(indexOf + 1);
        params = splitQueryParams(query);
    }

    return params;
}

function splitQueryParams(query) {
    var params = {}
    if (!query) {
        return params;
    }
    var nameValues = query.split('&');
    nameValues.forEach(function (nv) {
        if (nv.indexOf('=') > -1) {
            var nameValue = nv.split('='),
                name = nameValue[0],
                value = nameValue[1];

            value = decodeURIComponent(value.replace(/\+/g, '%20'));
            params[name] = value;
        }
    })
    return params;
}

function appendParamsToUrl(url, params) {
    if (params != null) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + stringifyParams(params);
    }
    return url;
}

function prepareForPost(url, postData) {
    var idx, requestUrl;
    if (url.startsWith('/dk?cmd=')) {
        idx = url.indexOf('&') + 1; // все параметры, кроме 1-го, "выносим" в body POST-запроса
    } else {
        idx = url.indexOf('?') + 1; // все параметры передаём в body POST-запроса
    }
    if (idx > 0) {
        requestUrl = url.substring(0, idx - 1);
        if (postData) {
            const query = url.substring(idx);
            const queryParams = splitQueryParams(query)
            Object.assign(postData, queryParams);
        }
    } else {
        requestUrl = url;
    }
    return requestUrl;
}

function ajax(requestData, redirectHandler) {
    var type = requestData.type || 'POST',
        data = requestData.data || {},
        contentType = requestData.contentType,
        dataType = requestData.dataType,
        responseType = requestData.responseType,
        url = requestData.url || '',
        isExternal = requestData.isExternal || /^(https?:)?\/\//.test(url),
        withCredentials = requestData.withCredentials || false,
        contentTypeMap = {'xml': 'application/xml, text/xml', 'html': 'text/html', 'text': 'text/plain', 'json': 'application/json, text/javascript', '*': '*/*'},
        headers = requestData.headers || {},
        responseHeaders = {},
        responseHeadersRegEx = /^(.*?):[ \t]*([^\r\n]*)\r?$/mg,
        beforeOpen = requestData.beforeOpen,
        xhr = new XMLHttpRequest();

    if (!redirectHandler) {
        redirectHandler = function(redirectLocation) {
            if (OK.navigation.redirect) {
                OK.navigation.redirect(redirectLocation);
            } else {
                document.location.href = decodeURIComponent(redirectLocation);
            }
        };
    }

    if ((type === 'POST') && (typeof data !== 'string')) {
        if (requestData.isFileUpload) {
            headers['Content-Type'] = 'application/octet-stream';
            // Кросс-браузерно в HTTP заголовках работают только ASCII символы. UTF-8 имена передавать можно, но
            // решения выглядят сложновато. Кроме того, обрезаем имя файла, чтобы заголовок не был слишком большим.
            var fileName = (data.name || '1.jpg').replace(/[^\u0000-\u007F]+/gi, '_').substring(0, 255);
            headers['Content-Disposition'] = 'attachment; filename="' + fileName + '"';
        }
        else {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';

            if (window.pageCtx.gwtHash) {
                data['gwt.requested'] = window.pageCtx.gwtHash;
            }
            data = stringifyParams(data);
        }
    }

    if (contentType && contentTypeMap.hasOwnProperty(contentType) && !headers.hasOwnProperty('Content-Type')) {
        headers['Content-Type'] = contentTypeMap[contentType];
    }

    if (dataType && contentTypeMap.hasOwnProperty(dataType) && !headers.hasOwnProperty('Accept')) {
        headers['Accept'] = dataType === '*' ? contentTypeMap[dataType] : contentTypeMap[dataType] + ', */*; q=0.01';
    }

    if (!isExternal && !headers.hasOwnProperty('TKN')) {
        headers['TKN'] = OK.tkn.get();
    }

    if (!isExternal && !headers.hasOwnProperty('STRD')) {
        headers['STRD'] = OK.isStateRedesign();
    }

    if (!isExternal && !headers.hasOwnProperty('STRV')) {
        headers['STRV'] = OK.getRedesignVersion();
    }

    if (!isExternal) {
        headers[OK.CLIENT_FLAGS_HEADER] = OK.getClientFlags();
    }

    var statId = OK.getStatId();
    if (!isExternal && statId) {
        headers[OK.STAT_ID_HEADER] = statId;
    }

    var result = new Promise(function(resolve, reject) {
        var requestStart = window.performance.now();

        if (beforeOpen) {
            beforeOpen({ xhr: xhr });
        }
        xhr.open(type, url, true);

        for (var header in headers) {
            if (headers.hasOwnProperty(header)) {
                xhr.setRequestHeader(header, headers[header]);
            }
        }
        if (!isExternal) {
            screens.apply(xhr);
        }
        if (responseType) {
            xhr.responseType = responseType;
        }

        if (withCredentials) {
            xhr.withCredentials = withCredentials;
        }

        xhr.onreadystatechange = function() {
            if (xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED) {
                var responseHeadersString = xhr.getAllResponseHeaders(),
                    match;
                while (match = responseHeadersRegEx.exec(responseHeadersString)) {
                    responseHeaders[match[1].toLowerCase()] = match[2];
                }

                //WARN!!!
                //Header names should be in lower case only
                if (!isExternal && responseHeaders['redirect-location']) {
                    redirectHandler(responseHeaders['redirect-location']);
                    xhr.rejectReason = 'Redirect';
                    reject(xhr, xhr.statusText, 'Redirect');
                    return;
                }
            }

            if (xhr.readyState !== XMLHttpRequest.DONE) {
                return;
            }

            if (xhr.status >= 200 && xhr.status < 300 || xhr.status === 304) {
                AjaxNavigationLog.log(window.performance.now() - requestStart, url, xhr.getResponseHeader('X-Render-Time'));

                var response = responseType
                        ? xhr.response
                        : xhr.responseText,
                    data = response;
                if (dataType === 'json') {
                    try {
                        data = JSON.parse(response);
                    } catch (ex) {
                        reject(ex);
                    }
                }

                if (!isExternal && responseHeaders['tkn']) {
                    OK.tkn.set(responseHeaders['tkn']);
                }

                if (!isExternal && responseHeaders.hasOwnProperty(OK.CLIENT_FLAGS_HEADER)) {
                    OK.setClientFlags(responseHeaders[OK.CLIENT_FLAGS_HEADER])
                }

                if (!isExternal && OK.state) {
                    window.OK.state.processXhrResponse(xhr);
                }

                OK.util.processCssHeaders(xhr).then(function () {
                    resolve({
                        response: data,
                        xhr: xhr
                    });
                });
            } else {
                reject(xhr);
            }
        };

        xhr.onerror = function() {
            reject(xhr);
        };

        /**
         * @type {AbortSignal}
         */
        var signal = requestData.signal;
        if (signal) {
            signal.addEventListener('abort', function () {
                xhr.abort();
                reject(xhr);
            });
        }

        xhr.send(data);
    });

    result.xhr = xhr;
    return result;
}

function getJSON(url, data, options) {
    return ajax(Object.assign(
        {
            type: 'GET',
            dataType: 'json',
            url: url,
            data: data
        },
        options
    ));
}

function trigger(element, eventName, data) {
    // Не обрабатываем текстовые ноды и комментарии
    if (!element || element.nodeType === 3 || element.nodeType === 8) {
        return;
    }

    // Не используем new CustomEvent() для поддержки IE9+
    var e = document.createEvent('CustomEvent');
    e.initCustomEvent(eventName, true, true, data);
    element.dispatchEvent(e);
}

/**
 * @param {string} text
 * @returns {string}
 */
function encodeHtml (text) {
    var element = document.createElement('div');

    function encodeHtmlWithoutSpace(text) {
        element.innerText = text;
        return element.innerHTML;
    }

    // В ие11 есть баг, что он заменяет пробелы на &nbsp;, поэтому будем пробелы обходить
    return text.split(' ').map(encodeHtmlWithoutSpace).join(' ');
}


function getBlockIds(xhr) {
    var blockIdsHeader = xhr.getResponseHeader(OK.navigation.HEADER);
    if (blockIdsHeader) {
        return blockIdsHeader.split(',');
    }
    return null;
}

function updateBlocks(data, blockIds) {
    var blocksContent = data.split(OK.navigation.SPLITER);

    blocksContent.forEach(function (blockContent, i) {
        if (blockIds[i]) {
            OK.hookModel.setHookContent(blockIds[i], blockContent);
        }
    });
}

/**
 * Заменяет спецсимволы XML на обычные
 *
 * @param {string} str
 */
function unescapeXml(str) {
    return str.replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, '\'');
}

function escapeXml(str) {
    return str.replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&apos;');
}

/**
 * Удаляет HTML-тэги разметки из строки.
 * Содержимое тэгов остается.
 *
 * @example
 * // Возвращает строку 'Климат Турина обладает большинством черт...'
 * stripTags('Климат <b>Турина</b> обладает большинством черт...');
 *
 * @example
 * // Возвращает строку 'В выражении a + (b * c) <= x^2 + cos(y) <= d * exp(a) знак <= может быть заменен на < (строго меньше)'
 * stripTags('В выражении <b>a + (b * c) <= x^2 + cos(y) <= d * exp(a)</b> знак <i><=</i> может быть <span class="some-class another class" id="vip_tag">заменен</span> на <i><</i> (<mark>строго меньше</mark>)');
 *
 * @param {string} str
 *      Обрабатываемая строка.
 * @return {string}
 *      Строка без HTML-тэгов разметки.
 */
function stripTags(str) {
    var div = document.createElement('div');
    div.innerHTML = str;
    return div.innerText;
}

/**
 * Добавление разделителей (пробелов) в запись десятичного числа
 *
 * @param {number} n
 */
function formatNumber(n) {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

/**
 * Обновляет блоки.
 * @param {{response: string, xhr: XMLHttpRequest}} data
 */
function updateBlockModelCallback(data) {
    var blockIds = getBlockIds(data.xhr);
    if (blockIds) {
        updateBlocks(data.response, blockIds);
    }
}

function getFormData(form) {
    return Array.prototype.slice.apply(form.getElementsByTagName('input'))
    .reduce(function (acc, input) {

        if (input.name) {
            acc[input.name] = input.type === 'checkbox'
                ? (input.checked ? 'on' : 'off')
                : input.value;
        }

        return acc;
    }, {});
}

/**
 * Преобразует строку с именем хук-блока из html-разметки до BlockId.
 * Например hook_Block_AppList -> AppList
 *
 * @param {string | undefined} fullBlockName
 *
 * @return {string | null}
 */
function replaceHookBlockName(fullBlockName) {
    if (fullBlockName) {
        return fullBlockName.replace('hook_Block_', '');
    }
    return null;
}

export default { prepareForPost, ajax, getJSON, trigger, encodeHtml, parseLink, extractParams, stringifyParams, appendParamsToUrl, getBlockIds, updateBlocks, updateBlockModelCallback, getFormData, unescapeXml, escapeXml, stripTags, formatNumber, replaceHookBlockName };

export { prepareForPost, ajax, getJSON, trigger, encodeHtml, parseLink, extractParams, stringifyParams, appendParamsToUrl, getBlockIds, updateBlocks, updateBlockModelCallback, getFormData, unescapeXml, escapeXml, stripTags, formatNumber, replaceHookBlockName };
